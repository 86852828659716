import React from 'react'
import { titleToId } from '../../../../utils/format'
import styles from './style.module.sass'

export default ({ dosage }) => {
  return (
    <div className={styles.dosage}>
      <div className={styles.anchor} id={titleToId(dosage.title)} data-js="scroll-section" />
      <h2 className={styles.title}>{dosage.title}</h2>

      <ul className={styles.list}>
        {dosage.items &&
          dosage.items.map((item, index) => (
            <li className={styles.item} key={item._key}>
              <p className={styles.index}>0{index + 1}</p>
              <p className={styles.info}>{item.title}</p>
              <p className={styles.text}>{item.text}</p>
            </li>
          ))}
      </ul>
    </div>
  )
}
