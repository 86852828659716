import React from 'react'
import cx from 'classnames'
import { titleToId } from '../../../../utils/format'
import styles from './style.module.sass'

export default ({ table }) => {
  const getIconByValue = value => {
    switch (value) {
      case 'plus':
        return <i className={styles.iconPlus} />
      case 'minus':
        return <i className={styles.iconMinus} />
    }
  }

  return (
    <div className={styles.table}>
      <div className={styles.anchor} id={titleToId(table.title)} data-js="scroll-section" />
      <h2 className={styles.title}>{table.title}</h2>
      <div className={styles.content}>
        <div className={styles.left}>
          <p className={styles.info}>{table.info1}</p>
          <p className={styles.description}>{table.description1}</p>
        </div>
        <div className={styles.right}>
          <p className={styles.info}>{table.info2}</p>
          <p className={styles.description}>{table.description2}</p>
        </div>
      </div>
      <div className={styles.box}>
        <div className={styles.row}>
          <div className={cx(styles.cell, styles.cellCorner)}></div>
          <div
            className={cx(styles.cell, styles.cellAlign)}
            dangerouslySetInnerHTML={{ __html: table.title_column_1 }}
          />
          <div
            className={cx(styles.cell, styles.cellAlign)}
            dangerouslySetInnerHTML={{ __html: table.title_column_2 }}
          />
        </div>

        {table.tableRows &&
          table.tableRows.map(row => (
            <div className={cx(styles.row, styles.rowLine)} key={row._key}>
              <div className={styles.cell}>
                {row.title} <span className={styles.cellSmall}>{row.info}</span>
              </div>
              <div className={cx(styles.cell, styles.cellIcon)}>{getIconByValue(row.column_1)}</div>
              <div className={cx(styles.cell, styles.cellIcon)}>{getIconByValue(row.column_2)}</div>
            </div>
          ))}
      </div>
    </div>
  )
}
